// import objectFitImages from 'object-fit-images';

// window.objectFitImages = objectFitImages;

import autocreate from 'autocreate.js';
import throttle from '@/utils/throttle';
import {supportsObjectFit, isEdge} from '@/utils/features';

// unfortunately this is necessary, because edge does support object-fit,
// but not on videos and this can't be detected with feature detection.
if ((!supportsObjectFit || isEdge) && !window.objectFitPolyfill) {
	document.documentElement.classList.add('no-objectfit');

	autocreate({
		selector: '[data-object-fit]',
    create: function(el) {
      this.resize = e => {
				const parent = el.parentNode;
				const parentWidth = parent.clientWidth;
				const parentHeight = parent.clientHeight;

				const width = el.videoWidth || el.naturalWidth;
				const height = el.videoHeight || el.naturalHeight;
				const factor = Math.max(
					parentWidth / width,
					parentHeight / height
				);
				el.style.width = `${width * factor}px`;
				el.style.height = `${height * factor}px`;
			};

			this.throttled = throttle(this.resize);

			window.addEventListener('resize', this.throttled);
			window.addEventListener('load', this.resize);

			el.oncanplay = el.onload = this.throttled;

			this.resize();

			setTimeout(() => {
				this.resize();
			}, 700);
    },
    destroy: function(el) {
      this.throttled && window.removeEventListener('resize', this.throttled);
      this.resize && window.removeEventListener('load', this.resize);
    }
	});
}
