import '@/polyfills';
import '@/handlers';
import map from 'staticfile'
// typeof Promise == "object" && console.info("forced promise");
import htmlComponent from 'htmlcomponent'

htmlComponent.setStaticLoader(map);
htmlComponent.query(document);

// TODO: maybe this needs to be refactored or placed somewhere else
window.addEventListener('load', e => {
	document.querySelector('.page-loader').addEventListener('transitionend', (e) => {
		document.documentElement.classList.add('page--ready');
	}, {once: true});
	document.documentElement.classList.add('page--loaded');
});
