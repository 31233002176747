export default function(dom, opts) {

	class Screencasts {
		constructor(screencasts, options = {}) {
			this.options = {
				baseClass: 'screencasts',
				slideClass: 'screencasts__slide',
				slideActiveClass: 'screencasts__slide--active',
				startSlide: 0,
				autoplay: true,
				...options,
			};
			this.screencasts = screencasts;
			this.slides = [].slice.call(screencasts.querySelectorAll(`.${this.options.slideClass}`), 0);
			this.currentIndex = null;
			this.init();
		}

		init() {
			this.start();
		}

		goTo(index = this.currentIndex) {
			if (index === this.currentIndex) {
				return;
			}

			if (this.currentIndex !== null) {
				const currentSlide = this.slides[this.currentIndex];
				const currentVideo = currentSlide.querySelector('video');

				if (currentVideo) {
					currentVideo.pause();
					currentSlide.addEventListener('transitionend', e => {
						currentVideo.currentTime = 0;
					}, {once: true});
				}
				currentSlide.classList.remove(this.options.slideActiveClass);
			}

			const newSlide = this.slides[index];
			const newVideo = newSlide.querySelector('video');

			if (newVideo) {
				newVideo.currentTime = 0;
				newVideo.loop = false;
				newSlide.addEventListener('transitionend', e => {
					newVideo.play();
				}, {once: true});

				if (this.options.autoplay) {
					newVideo.addEventListener('ended', e => {
						!this.stopped && this.next();
					}, {once: true});
				}
			}

			newSlide.classList.add(this.options.slideActiveClass);

			this.currentIndex = index;
		}

		next() {
			let index;

			if (this.currentIndex < this.slides.length - 1) {
				index = this.currentIndex + 1;
			}
			else {
				index = 0;
			}

			this.goTo(index);
		}

		start() {
			this.stopped = false;
			this.goTo(this.options.startSlide);
		}

		stop() {
			this.stopped = true;
		}
	}

	const screencasts = new Screencasts(dom, opts);
}
