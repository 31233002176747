
export default function(dom, opts) {

	const scrollThreshold = 50;
	const scrolledClass = 'header--scrolled';
	const scrolledUpClass = 'header--scrolled-up';
	const htmlScrolledClass = 'page--scrolled';

	const html = document.documentElement;

	let scrolled = false;
	let scrolledUp = false;
	let scrollPos = window.pageYOffset;

	window.addEventListener('load', scrollHandler);
	window.addEventListener('scroll', scrollHandler);
	window.addEventListener('resize', scrollHandler);

	function scrollHandler(e) {
		const bodyTop = document.body.getBoundingClientRect().top;
		const scrollTop = window.pageYOffset;

		if (scrollTop >= scrollThreshold) {
			dom.classList.add(scrolledClass);
			html.classList.add(htmlScrolledClass);
			scrolled = true;

			if (bodyTop > scrollPos) {
				dom.classList.add(scrolledUpClass);
				scrolledUp = true;
			}
			else {
				dom.classList.remove(scrolledUpClass);
				scrolledUp = false;
			}
		}
		else {
			dom.classList.remove(scrolledClass);
			dom.classList.remove(scrolledUpClass);
			html.classList.remove(htmlScrolledClass);
			scrolled = false;
			scrolledUp = false;
		}

		scrollPos = bodyTop;
	}
}
