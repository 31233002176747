import Scrrroll from 'scrrroll';
import {easeOutQuart} from 'easings.js';

Scrrroll.duration = 2500;
Scrrroll.easing = easeOutQuart;

export default function (dom, opts) {
	let options = {
		...opts
	};

	const target = document.querySelector(options.selector || dom.getAttribute('href'));

	if (target || options.top) {
		dom.addEventListener('click', e => {
			e.preventDefault();
			if (options.top) {
				Scrrroll.toTop();
			}
			else {
				Scrrroll.to(target);
			}
			return false;
		});
	}
}
