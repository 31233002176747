export default function(dom, opts) {

	class Slideshow {
		constructor(slideshow, options = {}) {
			this.options = {
				baseClass: 'slideshow',
				slideClass: 'slideshow__slide',
				slideActiveClass: 'slideshow__slide--active',
				slidePrevClass: 'slideshow__slide--prev',
				slideNextClass: 'slideshow__slide--next',
				buttonClass: 'slideshow__button',
				buttonPrevClass: 'slideshow__button--prev',
				buttonNextClass: 'slideshow__button--next',
				bulletsClass: 'slideshow__bullets',
				bulletClass: 'slideshow__bullet',
				bulletActiveClass: 'slideshow__bullet--active',
				startSlide: 0,
				...options
			};
			this.slideshow = slideshow;
			this.slides = [].slice.call(slideshow.querySelectorAll(`.${this.options.slideClass}`), 0);
			this.buttons = [].slice.call(slideshow.querySelectorAll(`.${this.options.buttonClass}`), 0);
			this.bullets = [].slice.call(slideshow.querySelectorAll(`.${this.options.bulletClass}`), 0);
			this.currentIndex = null;
			this.init();
		}

		init() {
			this.touchstartX = 0;
			this.touchstartY = 0;
			this.touchendX = 0;
			this.touchendY = 0;
			this.moveX = 0;
			this.moveY = 0;

			this.onTouchStart = this.touchStart.bind(this);
			this.onTouchMove = this.touchMove.bind(this);
			this.onTouchEnd = this.touchEnd.bind(this);

			this.slideshow.addEventListener('touchstart', this.onTouchStart, false);
			this.slideshow.addEventListener('mousedown', this.onTouchStart, false);
			window.addEventListener('touchmove', this.onTouchMove, {passive: false});
			this.slideshow.addEventListener('touchend', this.onTouchEnd, false);
			this.slideshow.addEventListener('mouseup', this.onTouchEnd, false);

			for (let button of this.buttons) {
				if (button.classList.contains(this.options.buttonPrevClass)) {
					button.addEventListener('click', e => {
						e.preventDefault();
						this.prev();
					});
				}
				else if (button.classList.contains(this.options.buttonNextClass)) {
					button.addEventListener('click', e => {
						e.preventDefault();
						this.next();
					});
				}
			}

			for (let bullet of this.bullets) {
				let index = this.bullets.indexOf(bullet);
				bullet.addEventListener('click', e => {
					const direction = index < this.currentIndex ? 'prev' : 'next';
					this.goTo(index, direction);
				});
			}

			this.goTo(this.options.startSlide);
		}

		touchStart(e) {
			this.touchstartX = e.changedTouches ? e.changedTouches[0].screenX : e.screenX;
			this.touchstartY = e.changedTouches ? e.changedTouches[0].screenY : e.screenY;
		}

		touchMove(e) {
			const minValue = 5; // threshold

			// Vertical scrolling does not work when you start swiping horizontally.
			this.moveX = Math.abs((e.changedTouches ? e.changedTouches[0].screenX : e.screenX) - this.touchstartX);
			this.moveY = Math.abs((e.changedTouches ? e.changedTouches[0].screenY : e.screenY) - this.touchstartY);

			if (this.moveX > minValue && this.moveX > this.moveY) {
				e.preventDefault();
				e.returnValue = false;
				return false;
			}
		}

		touchEnd(e) {
			this.touchendX = e.changedTouches ? e.changedTouches[0].screenX : e.screenX;
			this.touchendY = e.changedTouches ? e.changedTouches[0].screenY : e.screenY;

			this.moveX = Math.abs(this.touchendX - this.touchstartX);
			this.moveY = Math.abs(this.touchendY - this.touchstartY);

			if (this.touchendX < this.touchstartX && this.moveX > this.moveY) {
				this.next();
			}

			if (this.touchendX > this.touchstartX && this.moveX > this.moveY) {
				this.prev();
			}
		}

		goTo(index = this.currentIndex, direction = 'none') {
			if (index === this.currentIndex) {
				return;
			}

			if (this.currentIndex !== null) {
				this.slides[this.currentIndex].classList.remove(this.options.slideActiveClass);
				this.slides[this.currentIndex].classList.remove(this.options.slideNextClass);
				this.slides[this.currentIndex].classList.remove(this.options.slidePrevClass);
			}

			if (this.bullets[this.currentIndex]) {
				this.bullets[this.currentIndex].classList.remove(this.options.bulletActiveClass);
			}

			if (direction === 'prev') {
				this.slides[index].classList.remove(this.options.slideNextClass);
				this.slides[index].classList.add(this.options.slidePrevClass);
			}

			else if (direction === 'next') {
				this.slides[index].classList.remove(this.options.slidePrevClass);
				this.slides[index].classList.add(this.options.slideNextClass);
			}

			this.slides[index].classList.add(this.options.slideActiveClass);

			if (this.bullets[index]) {
				this.bullets[index].classList.add(this.options.bulletActiveClass);
			}

			this.currentIndex = index;
		}

		prev() {
			let index;

			if (this.currentIndex > 0) {
				index = this.currentIndex - 1;
			}
			else {
				index = this.slides.length - 1;
			}

			this.goTo(index, 'prev');
		}

		next() {
			let index;

			if (this.currentIndex < this.slides.length - 1) {
				index = this.currentIndex + 1;
			}
			else {
				index = 0;
			}

			this.goTo(index, 'next');
		}
	}

	const slideshow = new Slideshow(dom, opts);
}
