import comp0 from '@/app/sentry'
import comp1 from '@/app/slideshow'
import comp2 from '@/app/scrollto'
import comp3 from '@/app/header'
import comp4 from '@/app/screencasts'
import comp5 from '@/app/hamburger'
var map={}; 
map['app/sentry']=comp0;
map['app/slideshow']=comp1;
map['app/scrollto']=comp2;
map['app/header']=comp3;
map['app/screencasts']=comp4;
map['app/hamburger']=comp5;
export default map;