import events from 'eventslibjs'

export default function (dom, opts) {


	var options = {
		clsSelector: '.hamburger',
		clsMain: 'body',
		clsTrigger: 'hamburger',
		openCls: 'hamburger--is-active',
		menuSelector: '.mobile-menu',
		clsMenu: 'mobile-menu--active',
		clsScroll: 'no-scroll',
		overlayCls: 'overlay-active',
		openTrigger: '[data-toggle-navi]',
		closeTrigger: '[data-close-navi]',

		...opts
	};

	init()


	function init() {
		events.on('click', options.openTrigger, onClick)
	}

	function onClick() {
		getContainer().classList.toggle(options.openCls);
		openOverlay();
		openMenu();
	}

	function openOverlay() {
		getBody().classList.toggle(options.overlayCls);
		noScroll();
	}

	function noScroll() {
		getHtml().classList.toggle(options.clsScroll);
	}

	function openMenu() {
		getMenu().classList.toggle(options.clsMenu);
	}

	function getContainer() {
		return options.clsSelector ? dom.querySelector(options.clsSelector) : dom
	}

	function getBody() {
		return document.body
	}

	function getHtml() {
		return document.documentElement
	}

	function getMenu() {
		return options.menuSelector ? document.querySelector(options.menuSelector) : document
	}
}
